import {
  Chat,
  Country,
  CreateThreadPayload,
  NPSStatusResponse,
  Preferences,
  StrategyResult,
  Thread,
  Topic,
  UpdateThreadPayload,
  User,
  UserPreferences
} from '@/types';
import EventEmitter from 'eventemitter3';
import React from 'react';

import { createUseContext } from '../../../utils/create-use-context';
import { Region, SubscriptionSettings } from '../../admin/managers/subscriptions/data';
import { FeedbackPayload } from '../../thread/types';
import { TSpanEvent } from '../../trace/types';
import { Api, APIResponse, Fetch, Methods, RouteGeneratorFn } from './api';
import { AuthenticationError, NotFoundError, PermissionDeniedError } from './error';

export interface IAskBlueJApi {
  getChat: (threadId: string, chatId: string) => Promise<Chat>;
  abortChat: (threadId: string, chatId: string) => Promise<Chat>;
  createThread: (topic: string, initialThreadName: string) => Promise<Thread>;
  getThread: (threadId: string, page: number, perPage: number) => Promise<Thread>;
  getThreads: () => Promise<Thread[]>;
  getThreadsWithFilter: (searchText: string) => Promise<Thread[]>;
  updateThread: (threadId: string, title: string) => Promise<Thread>;
  deleteThread: (threadId: string) => Promise<void>;
  sendFeedback: (data: FeedbackPayload) => Promise<void>;
  generateThreadTitle: (threadId: string, chatId: string) => Promise<Thread>;
  recommendFollowUp: (threadId: string, question: string, answer: string, variant: Country) => Promise<string[]>;
  whoami: () => Promise<User>;
  deleteChat: (threadId: string, chatId: string) => Promise<void>;
  getStrategies: () => Promise<StrategyResult>;
  getUserNPSStatus: () => Promise<NPSStatusResponse>;
  getUserPreferences: () => Promise<UserPreferences>;
  updateUserPreferences: (preferences: Partial<Preferences>) => Promise<UserPreferences>;
  getTrace: (chatId: string) => Promise<TSpanEvent[]>;
  expectation: (question: string, strategy: string) => Promise<{ criteria: string; }>;
  createSavedPrompt: (savedPromptId: string, question: string) => Promise<{ savedPromptId: string }>;
  deleteSavedPrompt: (savedPromptId: string) => Promise<void>;
  getSavedPrompts: () => Promise<{ savedPromptId: string; question: string }[]>;
  getSubscriptionSettings: () => Promise<SubscriptionSettings>;
  getBillingPortalUrl: (region: Region) => Promise<{ url: string }>;
  getUserOrganization: () => Promise<{ name: string, sector: string }[]>;
  getTopics: () => Promise<{ topics: Topic[] }>;
  emitter: ApiEventEmitter;
}

type ApiEventEmitter = EventEmitter<'session invalid'>;

/**
 * This API is specifically for requests to the AskBlueJ Node Backend,
 * If you're requesting from other downstream sources (Bookcase, Curator, etc.) -- consider using the SourcesAPI or
 * making another similar class.
 */
export class AskBlueJApi extends Api implements IAskBlueJApi {
  private basePath = 'api';

  private _getChat = this.createFetch<null, Chat, { threadId: string, chatId: string }>(params => `threads/${params.threadId}/chats/${params.chatId}`, 'GET');
  private _abortChat = this.createFetch<{ threadId: string, chatId: string }, Chat, void>(`${this.basePath}/abort-chat`, 'POST');
  private _getThread = this.createFetch<null, Thread, { threadId: string, page: number, perPage: number }>(params => {
    const { threadId, page, perPage } = params;
    return `threads/${threadId}?page=${page}&perPage=${perPage}`;
  }, 'GET');
  private _createThread = this.createFetch<CreateThreadPayload, Thread, void>(`${this.basePath}/create-thread`, 'POST');
  private _getThreads = this.createFetch<null, Thread[], void>(`${this.basePath}/all-threads`, 'GET');
  private _deleteThread = this.createFetch<{ threadId: string }, Thread, void>(`${this.basePath}/delete-thread`, 'POST');
  private _sendFeedback = this.createFetch<FeedbackPayload, void, void>(`${this.basePath}/feedback`, 'POST');
  private _updateThread = this.createFetch<UpdateThreadPayload, Thread, void>(`${this.basePath}/update-thread`, 'POST');
  private _generateThreadTitle = this.createFetch<{ threadId: string, chatId: string }, Thread, void>(`${this.basePath}/generate-thread-title`, 'POST');
  private _recommendFollowUp = this.createFetch<{ threadId: string, question: string, answer: string, variant: Country }, string[], void>(`${this.basePath}/recommend-followup`, 'POST');
  private _whoami = this.createFetch<null, User, void>(`${this.basePath}/whoami`, 'POST');
  private _deleteChat = this.createFetch<{threadId: string, chatId: string }, Chat, void>(`${this.basePath}/delete-chat`, 'POST');
  private _getStrategies = this.createFetch<null, StrategyResult, null>(`${this.basePath}/manage/strategies`, 'GET');
  private _getUserNPSStatus = this.createFetch<null, NPSStatusResponse, null>('nps', 'GET');
  private _getUserPreferences = this.createFetch<null, UserPreferences, null>(`${this.basePath}/get-user-preferences`, 'GET');
  private _updateUserPreferences = this.createFetch<Partial<Preferences>, UserPreferences, void>(`${this.basePath}/update-user-preferences`, 'POST');
  private _getTrace = this.createFetch<null, TSpanEvent[], { chatId: string }>(params => `${this.basePath}/chat/${params.chatId}/trace`, 'GET');
  private _expectation = this.createFetch<{ question: string, strategy: string }, { criteria: string }, void>(`${this.basePath}/expectation`, 'POST');
  private _createSavedPrompt = this.createFetch<{ savedPromptId: string, question: string }, { savedPromptId: string }, void>(`${this.basePath}/create-saved-prompt`, 'POST');
  private _deleteSavedPrompt = this.createFetch<{ savedPromptId: string }, void, void>(`${this.basePath}/delete-saved-prompt`, 'POST');
  private _getSavedPrompts = this.createFetch<null, { savedPromptId: string, question: string }[], void>(`${this.basePath}/get-saved-prompts`, 'GET');
  private _getSubscriptionSettings = this.createFetch<null, SubscriptionSettings, void>(`${this.basePath}/subscription/settings`, 'GET');
  private _getBillingPortalUrl = this.createFetch<{ region: Region }, { url: string }, void>(`${this.basePath}/subscription/get-billing-portal`, 'GET');
  private _getThreadsWithFilter = this.createFetch<null, Thread[], { searchText: string }>(params => `${this.basePath}/filtered-threads?searchText=${params.searchText}`, 'GET');
  private _getUserOrganization = this.createFetch<null, { name: string, sector: string }[], void>(`${this.basePath}/users/current/organization`, 'GET');
  private _getTopics = this.createFetch<null, { topics: Topic[] }, void>(`${this.basePath}/manage/topics`, 'GET');

  public emitter: ApiEventEmitter;

  constructor(backendUrl = '', overriddenFetch?: Fetch | undefined) {
    super(backendUrl, overriddenFetch);
    this.emitter = new EventEmitter();
  }

  protected override createFetch<TPayload, TResponse, TParams>(apiSubRoute: string | RouteGeneratorFn<TParams>, method: Methods = 'POST'): (payload?: TPayload, params?: TParams) => APIResponse<TResponse> {
    const fetcher = super.createFetch<TPayload, TResponse, TParams>(apiSubRoute, method);

    return (payload?: TPayload, params?: TParams): APIResponse<TResponse> => {
      const response = fetcher(payload, params);
      response.parsedResponse().catch((err) => {
        // Only emitting auth related events that are a result of having a bad session
        if (err instanceof AuthenticationError || err instanceof PermissionDeniedError && err.isRevoked()) {
          this.emitter.emit('session invalid', { err });
        }
      });
      return response;
    }
  }

  public async getChat(threadId: string, chatId: string) {
    return this._getChat(null, { threadId, chatId }).parsedResponse();
  }

  public async abortChat(threadId: string, chatId: string) {
    return this._abortChat({ threadId, chatId }).parsedResponse();
  }

  public async createThread(topic: string, initialThreadName: string) {
    return this._createThread({ topic, initialThreadName }).parsedResponse();
  }

  public async getThread(threadId: string, page: number, perPage: number) {
    return this._getThread(null, { threadId, page, perPage }).parsedResponse();
  }

  public async getThreads() {
    return this._getThreads().parsedResponse();
  }

  public async getThreadsWithFilter(searchText: string) {
    return this._getThreadsWithFilter(null, { searchText }).parsedResponse();
  }

  public async updateThread(threadId: string, title: string) {
    return this._updateThread({ threadId, title, updateDate: false }).parsedResponse();
  }

  public async deleteThread(threadId: string) {
    await this._deleteThread({ threadId }).parsedResponse().catch((err) => {
      if (err instanceof NotFoundError) {
        return {};
      }
      throw err;
    });
  }

  public async sendFeedback(data: FeedbackPayload) {
    return this._sendFeedback(data).parsedResponse();
  }

  public async generateThreadTitle(threadId: string, chatId: string) {
    return this._generateThreadTitle({ threadId, chatId }).parsedResponse();
  }

  public async whoami() {
    return this._whoami().parsedResponse();
  }

  public async getStrategies() {
    return this._getStrategies().parsedResponse();
  }

  public async deleteChat(threadId: string, chatId: string) {
    await this._deleteChat({ threadId, chatId }).parsedResponse();
  }

  public async getUserNPSStatus() {
    return this._getUserNPSStatus().parsedResponse();
  }

  public async getUserPreferences() {
    return this._getUserPreferences().parsedResponse();
  }

  public async updateUserPreferences(preferences: Partial<Preferences>) {
    return this._updateUserPreferences(preferences).parsedResponse();
  }

  public async getTrace(chatId: string) {
    return this._getTrace(null, { chatId }).parsedResponse();
  }

  public async recommendFollowUp(threadId: string, question: string, answer: string, variant: Country) {
    return this._recommendFollowUp({ threadId, question, answer, variant }).parsedResponse();
  }

  public async expectation(question: string, strategy: string) {
    return this._expectation({ question, strategy }).parsedResponse();
  }

  public async createSavedPrompt(savedPromptId: string, question: string) {
    return this._createSavedPrompt({ savedPromptId, question }).parsedResponse();
  }

  public async deleteSavedPrompt(savedPromptId: string) {
    return this._deleteSavedPrompt({ savedPromptId }).parsedResponse();
  }

  public async getSavedPrompts() {
    return this._getSavedPrompts().parsedResponse();
  }

  public async getSubscriptionSettings() {
    return this._getSubscriptionSettings().parsedResponse();
  }

  public async getBillingPortalUrl(region: Region) {
    return this._getBillingPortalUrl({ region }).parsedResponse();
  }

  public async getUserOrganization() {
    return this._getUserOrganization().parsedResponse();
  }

  public async getTopics() {
    return this._getTopics().parsedResponse();
  }
}

const AskBluejApiContext = React.createContext<{ api: IAskBlueJApi } | null>(null);
AskBluejApiContext.displayName = 'AskBluejApiContext';
export const useAskBluejApiContext = createUseContext(AskBluejApiContext);

export const AskBlueJApiContextProvider = AskBluejApiContext.Provider;
